import { useMediaQuery } from "@mui/material";
import { hasValue } from "@xxl/common-utils";
import type { CategoryData } from "@xxl/frontend-api";
import { FixedLowPrice } from "@xxl/icons";
import noop from "lodash/noop";
import * as React from "react";
import { useState } from "react";
import type { Settings } from "react-slick";
import { CategoryIcon } from "../../../components/MegaMenu/Mobile/CategoryIcon";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { mobileMediaQuery } from "../../../utils/xxl-screen";
import {
  NAVIGATE_BACK_CODE,
  SHOW_ADDITIONAL_VIEW,
  SHOW_ALL_CATEGORIES_CODE,
} from "../../Search/Constants";
import type { SelectorCategory } from "../category-selector.helper";
import {
  categoriesToSelectorCategories,
  getSlidesPerView,
} from "../category-selector.helper";
import { SelectorItem } from "../SelectorItem/SelectorItem";
import { findCategory } from "./campaign-category-selector-helper";
import { CategoryList, SliderList } from "./CampaignCategorySelector.styled";

type CampaignCategoryListProps = {
  categories: CategoryData[];
  categoryPath: string[];
  onCategoryChange: (
    categoryPath: string[],
    isAdditionalViewSelected?: boolean
  ) => void;
  additionalViewSelectorLabel?: string;
};

const CampaignCategorySelector = ({
  additionalViewSelectorLabel,
  categories,
  categoryPath,
  onCategoryChange,
}: CampaignCategoryListProps) => {
  const { t } = useTranslations();
  const [campaignCategories, setCampaignCategories] = useState<
    SelectorCategory[]
  >([]);
  const [visibleCategories, setVisibleCategories] = useState<
    SelectorCategory[]
  >([]);
  const [backCategory, setBackCategory] = useState<SelectorCategory | null>(
    null
  );
  const [activeCategory, setActiveCategory] = useState<SelectorCategory | null>(
    null
  );
  const [shouldShowAll, setShouldShowAll] = useState(
    !hasValue(additionalViewSelectorLabel) && categoryPath.length === 0
  );
  const isAdditionalViewActive = !shouldShowAll && categoryPath.length === 0;

  const isMobile = useMediaQuery(mobileMediaQuery);

  const categoryShowAll = React.useMemo<SelectorCategory>(
    () => ({
      code: SHOW_ALL_CATEGORIES_CODE,
      name: t("campaign.show.all.button"),
      subCategories: [],
    }),
    [t]
  );

  const selectAdditionalView = () => {
    onCategoryChange([], true);
    setBackCategory(null);
    setShouldShowAll(false);
  };

  const showAll = React.useCallback(() => {
    onCategoryChange([]);
    setBackCategory(null);
    setShouldShowAll(true);
  }, [onCategoryChange]);

  const goBack = () => {
    if (categoryPath.length <= 1) {
      if (hasValue(additionalViewSelectorLabel) && categoryPath.length === 0) {
        selectAdditionalView();
        return;
      }
      showAll();
      return;
    }
    const path = categoryPath.slice(0, categoryPath.length - 1);
    onCategoryChange(path);
  };

  React.useEffect(() => {
    setCampaignCategories(categoriesToSelectorCategories(categories));
  }, [categories]);

  React.useEffect(() => {
    const updateBackCategory = (path: string[]) => {
      if (categoryPath.length === 1) {
        setBackCategory(categoryShowAll);
        return;
      }

      path.pop();
      const parent = findCategory(campaignCategories, path);
      setBackCategory(parent);
    };

    const categoriesHaveNotBeenConverted = campaignCategories.length === 0;
    if (categoriesHaveNotBeenConverted) {
      return;
    }

    const path = [...categoryPath];
    const noCategoryIsSelected = path.length === 0;

    if (noCategoryIsSelected) {
      setVisibleCategories(campaignCategories);
      setBackCategory(null);
      setActiveCategory(null);
      return;
    }

    try {
      const selectedCategory = findCategory(campaignCategories, path);
      const selectedCategoryHasSubcategories =
        selectedCategory.subCategories.length > 0;

      setVisibleCategories(
        selectedCategoryHasSubcategories
          ? selectedCategory.subCategories
          : [selectedCategory]
      );
      updateBackCategory(path);
      setActiveCategory(selectedCategory);
    } catch (_error) {
      showAll();
    }
  }, [categoryPath, campaignCategories, categoryShowAll, showAll]);

  React.useEffect(() => {
    if (
      categoryPath.length > 0 ||
      (hasValue(additionalViewSelectorLabel) && !isAdditionalViewActive)
    ) {
      window.addEventListener("popstate", goBack);
    }
    return (): void => window.removeEventListener("popstate", goBack);
  });

  const onSelectCategory = (selectedCategoryCodes: string[]) => {
    const lastCategoryCode = [...categoryPath].pop();
    const isOnlyOneCategorySelected = selectedCategoryCodes.length === 1;

    if (
      isOnlyOneCategorySelected &&
      lastCategoryCode === selectedCategoryCodes[0]
    ) {
      return;
    }

    const path = [...categoryPath];
    const selectedAndLastAreOnSameLevel = visibleCategories.some(
      ({ code }) => code === lastCategoryCode
    );

    if (selectedAndLastAreOnSameLevel) {
      path.pop();
    }

    selectedCategoryCodes.forEach((code) => path.push(code));
    onCategoryChange(path);
  };

  if (visibleCategories.length === 0) {
    return null;
  }

  const listContent: React.ReactElement[] = [];
  if (hasValue(additionalViewSelectorLabel)) {
    listContent.push(
      <SelectorItem
        handleClick={selectAdditionalView}
        isSelected={isAdditionalViewActive}
        isVisible={true}
        key={SHOW_ADDITIONAL_VIEW}
        name={additionalViewSelectorLabel}
        isClickable={!isAdditionalViewActive}
        icon={<FixedLowPrice />}
      />
    );
  }

  if (backCategory !== null) {
    listContent.push(
      <SelectorItem
        handleClick={goBack}
        isSelected={false}
        isVisible={true}
        key={NAVIGATE_BACK_CODE}
        name={backCategory.name}
        productCount={backCategory.productCount}
        isClickable={true}
        icon={<CategoryIcon code={NAVIGATE_BACK_CODE} />}
      />
    );
  } else {
    listContent.push(
      <SelectorItem
        handleClick={showAll}
        isSelected={!isAdditionalViewActive}
        isVisible={true}
        key={categoryShowAll.code}
        type={SHOW_ALL_CATEGORIES_CODE}
        name={categoryShowAll.name}
        productCount={categoryShowAll.productCount}
        isClickable={isAdditionalViewActive}
        icon={<CategoryIcon code={categoryShowAll.code} />}
      />
    );
  }

  if (activeCategory !== null) {
    const selectedCategoryCode = [...categoryPath].pop() ?? "";
    listContent.push(
      <SelectorItem
        handleClick={noop}
        isSelected={activeCategory.code === selectedCategoryCode}
        isVisible={true}
        key={activeCategory.code}
        name={activeCategory.name}
        productCount={activeCategory.productCount}
        isClickable={false}
        icon={<CategoryIcon code={activeCategory.code} />}
      />
    );
  }

  listContent.push(
    ...visibleCategories
      .filter(({ code }) => activeCategory?.code !== code)
      .map(({ code, name, productCount, subCategories }) => {
        const isSelected = ([...categoryPath].pop() ?? "") === code;
        return (
          <SelectorItem
            handleClick={() => onSelectCategory([code])}
            isSelected={isSelected}
            isVisible={true}
            key={code}
            name={name}
            productCount={productCount}
            isClickable={!isSelected || subCategories.length > 0}
            icon={<CategoryIcon code={code} />}
          />
        );
      })
  );

  const slidesPerView = getSlidesPerView(listContent.length);

  const config: Settings = {
    arrows: false,
    slidesToShow: slidesPerView,
    infinite: false,
    dots: true,
    variableWidth: true,
    swipeToSlide: true,
    useTransform: false,
    draggable: false,
  };

  return (
    <section className="category-selector" data-testid="react-category-list">
      {isMobile ? (
        <SliderList {...config}>{listContent}</SliderList>
      ) : (
        <CategoryList className={`category-selector__list-items`}>
          {listContent}
        </CategoryList>
      )}
    </section>
  );
};

export { CampaignCategorySelector };
